import { css } from '@emotion/react';
import { SimpleGrid } from '@mantine/core';
import { isError } from 'lodash';
import { useCallback, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { BiMessageRoundedError } from 'react-icons/bi';
import { useMount } from 'react-use';
import { DemoCode } from '~/components/DemoCode';
import { FrGa } from '~/modules/SDK/FrGa/FrGa';
/**
 * ErrorBoundary
 *
 * @example
 *   //
 *   const Component = () => {
 *     return (
 *       <ComponentBoundary filepath='pages/asiajye/_private/asiajye_Sidebar.tsx'>
 *         <SomeChildComponent />
 *         <AnotherChildComponent />
 *       </ComponentBoundary>
 *     )
 *   }
 */
export const ComponentBoundary = (props) => {
    return (<ErrorBoundary FallbackComponent={useCallback(fallbackProps => (<Fallback filepath={props.filepath} {...fallbackProps}/>), [props.filepath])}>
      {props.children}
    </ErrorBoundary>);
};
const Fallback = (props) => {
    const error = isError(props.error) ? props.error : undefined;
    const [opened, setOpened] = useState(false);
    useMount(() => {
        FrGa.event({
            前端運行時錯誤: {
                message: `[filepath:${props.filepath}]` + error?.message || '沒有正確提供錯誤訊息',
            },
        });
    });
    if (opened) {
        return (<SimpleGrid verticalSpacing={0} spacing={0} cols={1} css={css `
          position: fixed;
          width: 100%;
          height: 100%;
          /** 全螢幕 */
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 1000001;
          background-color: black;
        `} onClick={event => {
                props.resetErrorBoundary();
            }}>
        <div>抱歉！程式崩潰了，「請將手機橫放，並截圖並聯絡客服」，將立即為您處理！</div>
        <DemoCode css={css `
            & .mantine-Prism-line .token {
              font-size: 12px;
            }
          `}>
          {`${props.filepath}\n${error?.stack}`}
        </DemoCode>
      </SimpleGrid>);
    }
    return (<BiMessageRoundedError css={css `
        cursor: pointer;
        color: red;
        font-size: 96px;
      `} onClick={event => {
            setOpened(true);
        }}/>);
};
